import React, {useContext, Fragment, useState, useEffect} from 'react';
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import ORDER_TABLE_TYPES from '../../Components/OrderTable/orderTableTypes';
import {Row, Input} from '../../Widgets';
import {
  Breadcrumb,
  TabContainer,
  Information,
  Navbar,
} from '../../Components/ProfileLayout';
import useProfileLayout from '../../hooks/use-profile-layout';
import {Row as AntRow, Col} from 'antd';
import useOrderTable from '../../hooks/use-order-table';
import useDimensions from '../../hooks/use-dimension';
import LargeScreenTable from '../../Components/OrderTable/LargeScreenTable';
import SmallScreenTable from '../../Components/OrderTable/SmallScreenTable';
const qs = require('query-string');
const appConfig = require('../../data.json');

export default function OrderList(props) {
  const app = useContext(Context);
  const pathname = props.location.pathname;
  const query = qs.parse(props.location.search);

  const {profile, loading} = app.state;
  const {parent, tab} = useProfileLayout({pathname, tabName: query.tabName});
  const {orders, columns, filters, total, changeFilters} = useOrderTable({
    pathname: props.location.pathname,
    query,
    defaultType: ORDER_TABLE_TYPES.NORMAL,
  });
  const {dimension} = useDimensions();

  if (!profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <AntRow gutter={20}>
        <Col md={4} xs={24}>
          <Navbar pathname={pathname} />
        </Col>
        <Col md={1} xs={24} style={{height: 20}}></Col>
        <Col md={19} xs={24}>
          <Breadcrumb entry={parent} leafNode={tab} />
          <Information />
          <Row>
            <div style={{flex: 1}} />
            <Input.Search
              allowClear
              onSearch={(value) => changeFilters({search: value || ' '}, true)}
              placeholder="搜尋訂單編號"
              style={{width: 300, marginBottom: 10}}
            />
          </Row>
          <TabContainer
            entry={parent}
            leafNode={tab}
            onTabClick={(entry) => {
              changeFilters(
                {
                  type: {
                    order: [ORDER_TABLE_TYPES.NORMAL],
                    orderWaiting: [ORDER_TABLE_TYPES.WAITING_OR_PROCESSING],
                    orderItemRejected: [ORDER_TABLE_TYPES.ORDER_ITEM_REJECTED],
                    orderFinished: [ORDER_TABLE_TYPES.SUCCESS],
                  }[entry.name],
                  tabName: entry.name,
                },
                true,
              );
            }}>
            <div>
              {dimension.innerWidth > appConfig.breakpoints.sm ? (
                <LargeScreenTable
                  loading={loading}
                  orders={orders}
                  columns={columns}
                  filters={filters}
                  setFilters={changeFilters}
                  total={total}
                />
              ) : (
                <SmallScreenTable
                  loading={loading}
                  orders={orders}
                  columns={columns}
                  filters={filters}
                  setFilters={changeFilters}
                  total={total}
                />
              )}
            </div>
            {/* {(() => {
              switch (selected.name) {
                case 'order':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.NORMAL}
                      search={search}
                    />
                  );
                case 'orderWaiting':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.WAITING_OR_PROCESSING}
                      search={search}
                    />
                  );

                case 'orderItemRejected':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.ORDER_ITEM_REJECTED}
                      search={search}
                    />
                  );

                case 'orderFinished':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.SUCCESS}
                      search={search}
                    />
                  );
                default:
                  return null;
              }
            })()} */}
          </TabContainer>
        </Col>
      </AntRow>
    </ContentWrapper>
  );
}
